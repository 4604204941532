.cube {
    --dice-width: 12vh;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateY(-20deg) rotateX(-20deg);
    animation: cube 5s linear infinite;
    width: var(--dice-width);
    height: var(--dice-width);
}
.face {
    position: absolute;
    width: var(--dice-width);
    height: var(--dice-width);
    opacity: 0.95;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 5%;
}
.face div {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-around;
}
.face span {
    display: block;
    width: 20%;
    height: 100%;
    border-radius: 50%;
    background-color: #3065b0;
    margin: auto;
}
.face span.fake {
    background-color: transparent;
}
.face-avant {
    background-color: #bbb;
    transform: translateZ(calc(var(--dice-width) / 2));
}
.face-arriere {
    background-color: #bbb;
    transform: rotateY(180deg) translateZ(calc(var(--dice-width) / 2));
}
.face-droite {
    background-color: #e1e1e1;
    transform: rotateY(90deg) translateZ(calc(var(--dice-width) / 2));
}
.face-gauche {
    background-color: #e1e1e1;
    transform: rotateY(-90deg) translateZ(calc(var(--dice-width) / 2));
}
.face-haut {
    background-color: #959595;
    transform: rotateX(90deg) translateZ(calc(var(--dice-width) / 2));
}
.face-bas {
    background-color: #959595;
    transform: rotateX(-90deg) translateZ(calc(var(--dice-width) / 2));
}
@keyframes cube {
    from {
        transform: rotateY(-20deg) rotateZ(0) rotateX(-20deg);
    }
    to {
        transform: rotateY(-380deg) rotateZ(360deg) rotateX(-20deg);
    }
}
